import { useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Menu from '../components/header'
import levo from '../assets/levo.png'
import rocket from '../assets/rocket.jpg'
import { 
    Typography, 
    Container, 
    Grid, 
    Box, 
    Button, 
    Hidden, 
    Divider
} from '@material-ui/core'
import { 
    Facebook as FacebookIcon, 
    Instagram as InstagramIcon, 
    LinkedIn as LinkedInIcon,
    YouTube
} from '@material-ui/icons'
import ekvalu from '../assets/ekvalu.png'
import frametask from '../assets/frametask.png'
import legin from '../assets/legin.png'
import right from '../assets/right.png'
import hero from '../assets/hero.svg'
import hero_1 from '../assets/hero_1.png'
import hero_2 from '../assets/hero_2.png'
import hero_3 from '../assets/woman-1847044_1280.jpg'
import meeting from '../assets/meeting.png';
import structure from '../assets/structure.png';
import Item from './item'
import { useGlobal } from '../context';
import logo from '../assets/logo.png'

const useStyles = makeStyles((theme) => ({
    root: {},
    checked: {},
    titleBanner: {
        lineHeight: 1.2,
        color: '#000',
        fontSize: '44px'
    },
    paperProjects: {
        backgroundColor: '#F2F2F2', 
        padding: '100px', 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center',
        height: '100px', 
        borderRadius: '8px',
        ['@media (max-width: 779px)']: {
            height: '0px'
        }
    },
    paperWorldVacination: {
        padding: '20px', 
        alignItems: 'center', 
        backgroundColor: '#FFDE5A', 
        boxShadow: 'none', 
        borderRadius: '8px',
        ['@media (min-width: 780px)']: {
            display: 'flex', 
            justifyContent: 'space-between', 
        }
    },
    paperWorldVacinationButton: {
        textTransform: 'none', 
        backgroundColor: '#FFF', 
        color: '#888686', 
        borderRadius: '8px',
        ['@media (max-width: 779px)']: {
            marginTop: '20px'
        }
    },
    banner: {
        height: '80vh',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        backgroundColor: '#FAFAFA',
        marginTop: '104px',
        ['@media (max-width: 844px)']: {
            height: 'auto',
            paddingTop: '40px',
            paddingBottom: '40px',
            marginTop: '97px'
        }
    },
    imgSection: {
        width: '100%',
        borderRadius: '10px',
        height: '260px',
        // ['@media (min-width: 760px) and (max-width: 900px)']: {
        //     width: '50%',
        //     margin: 'auto',
        //     display: 'block'
        // }
    },
    button: {
        textTransform: 'none',
        backgroundColor: '#4A77FF',
        color: '#FFF',
        boxShadow: 'none',
        borderRadius: '10px',
        marginRight: '20px',
        paddingLeft: '20px',
        paddingRight: '20px'
    },
    titleCarachteristhics: {
        ['@media (min-width: 835px)']: {
            // textAlign: 'center'
        }
    },
    textCarachteristhics: {
        ['@media (min-width: 835px)']: {
            // textAlign: 'center',
            // width: '85%',
            // margin: 'auto'
        }
    },
    blockCarachteristhics: {
        paddingBottom: '60px', 
        paddingTop: '60px',
        ['@media (min-width: 780px)']: {
            width: '100%'
        }
    },
    blockSolutions: {
        paddingBottom: '0px', 
        paddingTop: '60px',
        ['@media (min-width: 780px)']: {
            width: '100%'
        }
    },
    img: {
        width: '120px',
    },
    boxHeros: {
        width: '50vw',
        '@media (min-width: 768px)': {
            width: '32vw',
        }
    },
    heros: {
        width: '100%',
        height: '200px',
        borderRadius: '15px',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        '@media (max-width: 320px)': {
          height: '13vh'
        },
        '@media (min-width: 321px) and (max-width: 374px)': {
          height: '11vh'
        },
        '@media (min-width: 375px) and (max-width: 413px)': {
          height: '11vh'
        },
        '@media (min-width: 375px) and (max-width: 413px) and (max-height: 667px)': {
          height: '13vh'
        },
        '@media (min-width: 414px) and (max-width: 427px)': {
          height: '13vh'
        },
        '@media (min-width: 414px) and (min-height: 896px)': {
          height: '11vh'
        },
        '@media (min-width: 428px) and (max-width: 767px)': {
          height: '11vh'
        },
        '@media (min-width: 768px) and (max-width: 780px)': {
          height: '12vh'
        },
        '@media (min-width: 1024px) and (max-width: 1180px) and (max-height: 820px) and (orientation: landscape)': {
          height: '21vh'
        },
        '@media (max-height: 414px) and (orientation: landscape)': {
          height: '32vh'
        }
    },
    boxSolution: {
        backgroundColor: '#FAFAFA',
        padding: '30px',
        borderRadius: '15px',
        ['@media (min-width: 780px)']: {
            minHeight: '195px',
        }
    }
}));

const TikTokIcon = ({ color = "#373737" }) => {
    return (
        <svg
            fill={color}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 50"
            width="26px"
            height="26px"
        >
            <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z" />
        </svg>
    );
};

function Home() {

    const isMobile = window.innerWidth <= 768;

    const classes = useStyles();
    const sectionRef = useRef(null);

    const scrollToSection = () => {
        if (sectionRef.current) {
            sectionRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const { translated_messages } = useGlobal();

    const companies = [
        {
            'href': 'https://www.ekvalu.com.br/',
            'src': ekvalu,
            'width': '140px',
            'status': 'production',
            'has_cnpj': true,
            'name': 'Ekvalu',
            'short_description': translated_messages.short_description_ekvalu,
            'description': translated_messages.description_ekvalu,
            'segment': 'Retailtech'
        },
        {
            'href': 'https://levo.com.br',
            'src': levo,
            'width': '120px',
            'status': 'production',
            'has_cnpj': true,
            'name': 'Levo',
            'short_description': translated_messages.short_description_levo,
            'description': translated_messages.description_levo,
            'segment': 'Retailtech'
        }
    ]

    const heros = [
        hero,
        hero_1,
        hero_2,
        hero_3
    ]

    const solutions = [
        {
            'name': 'Frametask',
            'href': 'https://www.frametask.appandwebstudio.com.br/',
            'logotype': frametask,
            'description': translated_messages.page_home_section_solutions_box_description_frametask
        },
        {
            'name': 'Legin',
            'href': 'https://www.legin.appandwebstudio.com.br/',
            'logotype': legin,
            'description': translated_messages.page_home_section_solutions_box_description_legin
        },
        {
            'name': 'Levo',
            'href': 'https://www.levo.com.br/',
            'logotype': levo,
            'description': translated_messages.page_home_section_solutions_box_description_levo
        },
        {
            'name': 'Ekvalu',
            'href': 'https://www.ekvalu.com.br/',
            'logotype': ekvalu,
            'description': translated_messages.page_home_section_solutions_box_description_ekvalu
        }
    ]

    return (
        <div className={classes.root}>

            <Menu />

            <div className={classes.banner}>
                <Container>
                    <Grid container alignItems='center'>
                        <Hidden only={['md', 'lg', 'xl']}>
                            <Box 
                                className={classes.boxHeros}
                            >
                                <Grid spacing={1} container>
                                    {heros.map((hero, index) => (
                                        <Grid item md={2} xs={6}>
                                            <Box
                                                className={classes.heros} 
                                                style={{
                                                    backgroundImage: `url(${hero})`,
                                                }}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>
                        </Hidden>
                        <Grid md={6} xs={12}>
                            <img src={logo} className={classes.img} draggable="false" style={{ marginTop: '30px' }} />
                            {/* <Typography className={classes.titleBanner}>
                                {translated_messages.page_home_title}
                            </Typography> */}
                            <Typography color="textSecondary" variant="h6" style={{ marginTop: '5px', width: '90%', fontSize: '22px' }}>
                                {translated_messages.page_home_subtitle}
                            </Typography>
                            {/* <Button onClick={scrollToSection} className={classes.button} size="large" style={{ marginTop: '20px' }}>
                                {translated_messages.page_home_button_banner}
                            </Button> */}
                        </Grid>
                        <Hidden smDown>
                            <Grid md={2}></Grid>
                            <Grid md={4}>
                                <Grid spacing={3} container>
                                    {heros.map((hero, index) => (
                                        <Grid item md={6}>
                                            <Box
                                                className={classes.heros} 
                                                style={{
                                                    backgroundImage: `url(${hero})`,
                                                }}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        </Hidden>
                    </Grid>
                </Container>
            </div>

            <Container className={classes.blockSolutions}>
                <Typography variant="h5">
                    {translated_messages.page_home_section_solutions_box_title}
                </Typography>
                <Grid spacing={3} container style={{ marginTop: '10px' }}>
                    {solutions.map((solution, index) => (
                        <Grid item md={3} xs={12} key={index}>
                            <Box
                                className={classes.boxSolution}
                            >
                                <Box
                                    style={{
                                        minHeight: '65px',
                                        alignItems: 'center',
                                        display: 'flex'
                                    }}
                                >
                                    <img src={solution.logotype} style={{ width: '100px', marginBottom: '10px' }} />
                                </Box>
                                <Typography>{solution.description}</Typography>
                                <br />
                                <a href={solution.href} target="_blank" style={{ textDecoration: 'none' }}>
                                    <Typography style={{ color: '#4A77FF' }}>Acessar</Typography>
                                </a>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Container>

            {/* <Container className={classes.blockCarachteristhics}>
                <Typography variant="h5" className={classes.titleCarachteristhics} style={{ marginBottom: '10px' }}>{translated_messages.page_home_section_characteristics_title}</Typography>
                <Typography color="textSecondary" variant="body1" className={classes.textCarachteristhics}>
                    {translated_messages.page_home_section_characteristics_subtitle}
                </Typography>
                <Grid spacing={3} container style={{ marginTop: '10px' }}>
                    <Grid item md={4} xs={12}>
                        <div 
                            className={classes.imgSection}
                            style={{
                                backgroundImage: `url(${right})`,
                                backgroundSize: '100%'
                            }}
                        />
                        <Typography variant="h6" style={{ marginTop: '20px' }}>
                            {translated_messages.page_home_section_characteristics_first_column_title}
                        </Typography>
                        <Typography color="textSecondary" variant="body1" style={{ marginTop: '5px' }}>
                            {translated_messages.page_home_section_characteristics_first_column_subtitle}
                        </Typography>
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <div 
                            className={classes.imgSection}
                            style={{
                                backgroundImage: `url(${meeting})`,
                                backgroundSize: '100%'
                            }}
                        />
                        <Typography variant="h6" style={{ marginTop: '20px' }}>
                            {translated_messages.page_home_section_characteristics_second_column_title}
                        </Typography>
                        <Typography color="textSecondary" variant="body1" style={{ marginTop: '5px' }}>
                            {translated_messages.page_home_section_characteristics_second_column_subtitle}
                        </Typography>
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <div 
                            className={classes.imgSection}
                            style={{
                                backgroundImage: `url(${rocket})`,
                                backgroundSize: '100%'
                            }}
                        />
                        <Typography variant="h6" style={{ marginTop: '20px', alignItems: 'center', display: 'flex', gap: '5px' }}>
                            {translated_messages.page_home_section_characteristics_third_column_title}
                        </Typography>
                        <Typography color="textSecondary" variant="body1" style={{ marginTop: '5px' }}>
                            {translated_messages.page_home_section_characteristics_third_column_subtitle}
                        </Typography>
                    </Grid>
                </Grid>
            </Container> */}

            <Box style={{ backgroundColor: '#FAFAFA', marginTop: '60px' }}>
                <Container style={{paddingBottom: '80px', paddingTop: '80px'}}>
                    <Typography variant="h5">{translated_messages.page_home_section_ceo_title}</Typography>
                    <Typography color="textSecondary" variant="body1" style={{ marginTop: '20px' }}>{translated_messages.page_home_section_ceo_subtitle}</Typography>
                </Container>
            </Box>
            <Container ref={sectionRef} style={{paddingBottom: '50px',paddingTop: '60px'}}>
                <Typography variant="h5">{translated_messages.page_home_section_solutions_title}</Typography>
                {/* <Typography variant="body1" color="textSecondary">{translated_messages.page_home_section_solutions_subtitle}</Typography> */}
                <Grid container spacing={3} style={{marginTop: '15px'}}>
                    {/* {companies.map((company, index) => (
                        <Grid key={index} item md={6} xs={12}>
                            <Item data={company} />
                        </Grid>
                    ))} */}
                    <Grid item md={12} xs={12}>
                        <img 
                            draggable="false"
                            src={structure} 
                            style={{ 
                                width: isMobile ? '100%' : '70%', 
                                display: 'block', 
                                margin: 'auto' 
                            }} 
                        />
                    </Grid>
                </Grid>
            </Container>

            <Container style={{paddingBottom: '50px'}}>
                <Typography variant="h5">{translated_messages.page_home_section_history_title}</Typography>
                <hr style={{ border: '0.5px solid #D2D2D2', width: '150px', marginBottom: '20px', display: 'inline-block', marginTop: '15px' }} />
                <Typography variant="body1">
                    {translated_messages.page_home_section_history_subtitle}
                </Typography>
            </Container>

            <Box style={{ backgroundColor: '#FAFAFA' }}>
                <Container style={{paddingBottom: '80px', paddingTop: '80px'}}>
                    {/* <Typography variant="h4">São Paulo</Typography>
                    <Divider 
                        style={{
                            marginTop: '30px', 
                            marginBottom: '30px', 
                            width: '11vw'
                        }}
                    />
                    <Typography variant="body1">Avenida Presidente Juscelino Kubitschek, 2041 Complexo JK, Torre B, 5º andar - Vila Olímpia, São Paulo - SP, 04543-011</Typography> */}
                    <a href="mailto:contato@appandwebstudio.com.br" subject="Gostaria de tirar uma dúvida" style={{ textDecoration: 'none', color: 'black', display: 'block', width: 'fit-content' }}>
                        <Typography style={{ marginTop: '20px', width: 'fit-content' }}>contato@appandwebstudio.com.br</Typography>
                    </a>
                    <Box style={{ marginTop: '30px', alignItems: 'center', display: 'flex', gap: '10px' }}>
                        <a href="https://www.facebook.com/appandwebstudio.com.br" target="_blank">
                            <FacebookIcon style={{color: '#373737', cursor: 'pointer', fontSize: '28px'}} />
                        </a>
                        <a href="https://www.instagram.com/appandwebdevelopmentstudio/" target="_blank">
                            <InstagramIcon style={{color: '#373737', cursor: 'pointer', fontSize: '28px'}} />
                        </a>
                        <a href="https://www.linkedin.com/company/app-and-web-development-studio/" target="_blank">
                            <LinkedInIcon style={{color: '#373737', cursor: 'pointer', fontSize: '28px'}} />
                        </a>
                        <a href="https://www.tiktok.com/@appandweb" target="_blank">
                            <TikTokIcon style={{cursor: 'pointer'}} />
                        </a>
                        <a href="http://www.youtube.com/@AppWebDevelopmentStudio" target="_blank">
                            <YouTube style={{color: '#373737', cursor: 'pointer', fontSize: '28px'}} />
                        </a>
                    </Box>
                    <Hidden smDown>
                        <Typography variant="body1" style={{ textAlign: 'right', color: '#C4C4C4', marginTop: '80px' }}>© {new Date().getFullYear()} {translated_messages.page_home_footer_coporate_name}</Typography>
                    </Hidden>
                    <Hidden smUp>
                    <Typography variant="body1" style={{ color: '#C4C4C4', marginTop: '40px' }}>© {new Date().getFullYear()} {translated_messages.page_home_footer_coporate_name}</Typography>
                    </Hidden>
                </Container>
            </Box>

        </div>
    );
}

export default Home;
import { Box } from "@material-ui/core";
import { FavoriteRounded } from "@material-ui/icons";

export default {
  
  page_home_title: (
    <>
      <Box
        sx={{
          maxWidth: '600px'
        }}
      >
        Improving <span style={{color: '#FFDE5A'}}>lives</span>
      </Box>
    </>
  ),
  page_home_subtitle: (
    <>
      {/* Our <b>mission</b> is to maximize life and everyday life on Earth by creating purposeful solutions to real problems and challenges. We want to make a difference in the lives of as many people as possible. */}
      We build technologies and solutions that provide greater quality of life and advances for society, also boosting the success of companies.
    </>
  ),
  page_home_button_banner: 'View solutions',
  page_home_button_contact: 'Contact us',

  modal_contact_title: 'We will contact you',
  modal_contact_subtitle: 'Fill in the fields below',
  modal_contact_field_name: 'Name',
  modal_contact_field_email: 'E-mail',
  modal_contact_field_contact: 'Contact',
  modal_contact_field_message: 'Message',
  modal_contact_field_message_placeholder: 'What would you like to talk about? Any of our solutions?',
  modal_contact_button: 'Send',
  modal_contact_message_success: 'Message sent successfully',
  modal_contact_message_error: 'We had a problem sending the message. Try again!',

  page_home_section_solutions_box_title: 'Our products',
  page_home_section_solutions_box_description_frametask: 'We assist in managing personal cash flow, objectives and expenses.',
  page_home_section_solutions_box_description_legin: 'We automate documents and contracts using Legal Design.',
  page_home_section_solutions_box_description_levo: 'We increase mobility, facilitating vehicle acquisition.',
  page_home_section_solutions_box_description_ekvalu: 'We generate access to products and services related to the equestrian world.',
  page_home_section_characteristics_title: 'Some of our characteristics',
  page_home_section_characteristics_subtitle: 'Our approach prioritizes innovation and the search for impactful solutions, with an unwavering commitment to excellence. We are determined to face diverse challenges and provide unique experiences to our users and customers.',
  page_home_section_characteristics_first_column_title: 'Companies and solutions with purpose',
  page_home_section_characteristics_first_column_subtitle: 'The diversification of segments happens naturally as we see the need to solve some pain.',
  page_home_section_characteristics_second_column_title: 'We challenge ourselves every day',
  page_home_section_characteristics_second_column_subtitle: 'We monitor and study data from our users and solutions at all times, generating constant improvements and innovations.',
  page_home_section_characteristics_third_column_title: (<> We love technology and engineering </>),
  page_home_section_characteristics_third_column_subtitle: 'We are restless in building solutions that involve major challenges. We are constantly working on solutions involving software and hardware.',

  page_home_section_ceo_title: `”If we are improving people's lives, we are moving in the right direction!”`,
  page_home_section_ceo_subtitle: 'Patrick Faciroli, Founder & CEO of App&Web',

  page_home_section_solutions_title: 'Corporate structure',
  page_home_section_solutions_subtitle: 'Our solutions are distributed in companies we control and in tools operated directly by us.',
  page_home_section_solutions_title_section_companies: 'Companies',
  page_home_section_solutions_title_section_solutions: 'Solutions',

  page_home_section_history_title: 'Our story so far',
  page_home_section_history_subtitle: (
    <>
      We were born in <b>2017</b> with minimal resources in a room, in Franca, in the interior of the state of São Paulo. Always driven by an insane passion for technology and engineering.
      <br /><br />
      Our first solution was <b><a href="https://levo.com.br" style={{ color: '#000' }}>Levo</a></b> in 2017, which consists of a company that mediates the relationship between car buyers and sellers, helping with mobility and in 2023, we launched <b><a href="https://ekvalu.com.br" style={{ color: '#000' }}>Ekvalu</a></b> which deals with a marketplace for equestrian products.
      <br /><br />
      The company has a strategic team and offices both in São Paulo - SP and in the interior of the state, in Franca - SP.
      <br /><br />
      We launched two tools in <b>2024</b>, <a href="https://frametask.appandwebstudio.com.br" style={{ color: '#000' }}>Frametask</a> for individuals and <a href="https://legin.appandwebstudio.com.br" style={{ color: '#000' }}>Legin</a> for legal entities.
      <br /><br />
      Our vision is to be a Brazilian Big Tech that can help directly change people's lives by applying technology and engineering into strategic solutions.
    </>
  ),

  page_home_footer_coporate_name: 'App&Web Tecnologia LTDA',

  modal_solution_visit_label: 'Access',
  modal_solution_label_name: 'Name',
  modal_solution_label_segment: 'Segment',

  short_description_ekvalu: 'Equestrian Marketplace',
  description_ekvalu: 'We want to change the way people in general relate to the Horse, which is a fantastic animal. We are a unique and niche marketplace to bring products related to this animal to as many people as possible.',

  short_description_levo: 'Vehicle classifieds',
  description_levo: 'We aim to provide a unique vehicle buying and selling experience for all links in the chain, whether reseller/seller or buyer.',

  short_description_frametask: 'Financial control, goals and personal tasks',
  description_frametask: 'Brazilians cannot manage their money and therefore achieve their dreams. We are a platform for managing financial flow, dreams/goals and everyday tasks. With our AI, we are able to enable a better living condition for users, including helping them achieve their goals.',

  short_description_legin: 'Document creation and management',
  description_legin: 'Legal documents do not need to be text-only. Ease of understanding the document is the most important thing. We are a design ecosystem for legal documents.'
  
};
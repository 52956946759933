import { Typography, Box, Container, Grid, TextField, CircularProgress, Button } from "@material-ui/core";
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Menu from "../../components/header";
import { useState } from "react";
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';

const CssTextField = withStyles({
    root: {
      '& label.Mui-focused': {
          color: '#757575'
      },
      '& .MuiInput-underline:after': {
        //borderBottomColor: '#757575',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderRadius: '10px'
        },
        '&:hover fieldset': {},
        '&.Mui-focused fieldset': {
          borderColor: '#757575',
          borderRadius: '10px'
        },
      },
    },
})(TextField);

const useStyles = makeStyles((theme) => ({
    button: {
        textTransform: 'none',
        backgroundColor: 'rgb(6, 62, 249)',
        boxShadow: 'none',
        fontFamily: 'RobotoLight',
        borderRadius: '8px',
        color: '#FFF',
        paddingLeft: '10px',
        paddingRight: '10px'
    }
}));

const Requisition = () => {

    const classes = useStyles();

    const [nameContact, setNameContact] = useState();
    const [emailContact, setEmailContact] = useState();
    const [cellphoneContact, setCellphoneContact] = useState();

    const [nameProject, setNameProject] = useState();
    const [descriptionProject, setDescriptionProject] = useState();
    const [importantPointsProject, setImportantPointsProject] = useState();
    const [deadlineProject, setDeadlineProject] = useState();

    const handleSendRequisition = () => {

        setIsLoading(true);
        setSuccessfulSended(true);

    }

    const [isLoading, setIsLoading] = useState(false);

    const [successfulSended, setSuccessfulSended] = useState(false);

    const [menuHeight, setMenuHeight] = useState();

    return (
        <>
            <Menu handleHeight={(e) => {setMenuHeight(e)}} />
            {successfulSended ? 
                <>
                    <Box 
                        style={{ 
                            backgroundColor: '#F2F2F2', 
                            height: `calc(100vh - ${menuHeight}px)`
                        }}>
                        <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <Box>
                                <CheckCircleRoundedIcon style={{ color: 'green', height: '120px', width: '120px', display: 'block', margin: 'auto' }} />
                                <Typography align="center" variant="h5" style={{ marginTop: '10px' }}>Dados enviados com sucesso!</Typography>
                                <Typography align="center" variant="body1" style={{ marginTop: '10px', marginBottom: '20px' }}>Uma cópia foi enviada ao seu e-mail e por lá já poderemos continuar evoluindo o seu projeto. Até breve!</Typography>
                                <Button onClick={() => {window.location = '/'}} style={{ display: 'block', margin: 'auto' }} className={classes.button}>Ir para página inicial</Button>
                            </Box>
                        </Container>
                    </Box>
                </>
            :
                <>
                    <Box style={{ backgroundColor: '#F2F2F2', paddingTop: '40px', paddingBottom: '40px' }}>
                        <Container>
                            <Typography variant="h5">Vamos te ajudar a desenvolver sua ideia</Typography>
                            <Typography variant="body1">Forneça os dados solicitados abaixo para entendermos melhor o seu projeto</Typography>
                        </Container>
                    </Box>
                    <Container style={{ paddingTop: '40px', paddingBottom: '40px' }}>
                        <Grid container spacing={3}>
                            <Grid item md={12} xs={12}>
                                <Typography>Informe seus dados para contato</Typography>
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <CssTextField InputLabelProps={{ style: { fontFamily: 'RobotoLight' } }} InputProps={{ style: { fontFamily: 'RobotoLight' } }} onChange={(e) => setNameContact(e.target.value)} value={nameContact} id="outlined-basic" label="Seu nome completo" variant="outlined" fullWidth />
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <CssTextField InputLabelProps={{ style: { fontFamily: 'RobotoLight' } }} InputProps={{ style: { fontFamily: 'RobotoLight' } }} onChange={(e) => setEmailContact(e.target.value)} value={emailContact} id="outlined-basic" label="Seu e-mail" variant="outlined" fullWidth />
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <CssTextField InputLabelProps={{ style: { fontFamily: 'RobotoLight' } }} InputProps={{ style: { fontFamily: 'RobotoLight' } }} onChange={(e) => setCellphoneContact(e.target.value)} value={cellphoneContact} id="outlined-basic" label="Seu celular" variant="outlined" fullWidth />
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <Typography>Informe dados do projeto</Typography>
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <CssTextField InputLabelProps={{ style: { fontFamily: 'RobotoLight' } }} InputProps={{ style: { fontFamily: 'RobotoLight' } }} onChange={(e) => setNameProject(e.target.value)} value={nameProject} id="outlined-basic" label="Nome do projeto" variant="outlined" fullWidth />
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <CssTextField multiline rows={10} InputLabelProps={{ style: { fontFamily: 'RobotoLight' } }} InputProps={{ style: { fontFamily: 'RobotoLight'} }} onChange={(e) => setDescriptionProject(e.target.value)} value={descriptionProject} id="outlined-basic" label="Descrição do projeto" variant="outlined" fullWidth />
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <CssTextField multiline rows={10} InputLabelProps={{ style: { fontFamily: 'RobotoLight' } }} InputProps={{ style: { fontFamily: 'RobotoLight' } }} onChange={(e) => setImportantPointsProject(e.target.value)} value={importantPointsProject} id="outlined-basic" label="Pontos importantes do projeto" variant="outlined" fullWidth />
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <CssTextField multiline rows={10} InputLabelProps={{ style: { fontFamily: 'RobotoLight' } }} InputProps={{ style: { fontFamily: 'RobotoLight' } }} onChange={(e) => setImportantPointsProject(e.target.value)} value={importantPointsProject} id="outlined-basic" label="Expectativa ou resultado esperado do projeto" variant="outlined" fullWidth />
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <CssTextField multiline rows={10} InputLabelProps={{ style: { fontFamily: 'RobotoLight' } }} InputProps={{ style: { fontFamily: 'RobotoLight' } }} onChange={(e) => setImportantPointsProject(e.target.value)} value={importantPointsProject} id="outlined-basic" label="Links para referência (Benchmarking). Se houver." variant="outlined" fullWidth />
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <CssTextField multiline rows={10} InputLabelProps={{ style: { fontFamily: 'RobotoLight' } }} InputProps={{ style: { fontFamily: 'RobotoLight' } }} onChange={(e) => setImportantPointsProject(e.target.value)} value={importantPointsProject} id="outlined-basic" label="Já tem algo pronto? Descreva." variant="outlined" fullWidth />
                            </Grid>
                            <Grid item md={3} xs={12}>
                                <CssTextField InputLabelProps={{ shrink: true, style: { fontFamily: 'RobotoLight' } }} InputProps={{ style: { fontFamily: 'RobotoLight' } }} onChange={(e) => setDeadlineProject(e.target.value)} value={deadlineProject} id="outlined-basic" label="Prazo máximo" variant="outlined" fullWidth type="date" />
                            </Grid>
                            <Grid item md={3} xs={12}>
                                <CssTextField InputLabelProps={{ style: { fontFamily: 'RobotoLight' } }} InputProps={{ style: { fontFamily: 'RobotoLight' } }} onChange={(e) => setEmailContact(e.target.value)} value={emailContact} id="outlined-basic" placeholder="Ex: Comércio eletrônico" label="Segmento" variant="outlined" fullWidth />
                            </Grid>
                            <Grid item md={3} xs={12}>
                                <CssTextField InputLabelProps={{ style: { fontFamily: 'RobotoLight' } }} InputProps={{ style: { fontFamily: 'RobotoLight' } }} onChange={(e) => setEmailContact(e.target.value)} value={emailContact} id="outlined-basic" placeholder="Ex: E-commerce" label="Tipo de aplicação" variant="outlined" fullWidth />
                            </Grid>
                            <Grid item md={3} xs={12}>
                                <CssTextField 
                                    InputLabelProps={{ style: { fontFamily: 'RobotoLight' } }} 
                                    InputProps={{ style: { fontFamily: 'RobotoLight' } }} 
                                    onChange={(e) => setEmailContact(e.target.value)} 
                                    value={emailContact} 
                                    id="outlined-basic" 
                                    placeholder="Ex: E-commerce" 
                                    label="Orçamento máximo" 
                                    variant="outlined" 
                                    fullWidth
                                />
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <Typography color="textSecondary">Obs: Posteriormente será possível enviar materiais, protótipos e afins, caso haja.</Typography>
                            </Grid>
                            <Grid item md={12} xs={12} style={{display: 'flex', flexDirection: 'row-reverse'}}>
                                <Button onClick={handleSendRequisition} className={classes.button} disabled={!nameContact || isLoading ? true : false} variant="contained" color="primary" size="large" fullWidth>
                                    {isLoading ?
                                        <CircularProgress color="#FFF" size={26} />
                                    :
                                        'Enviar dados'
                                    }
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>
                </>
            }
        </>
    )
}

export default Requisition;
import { Typography, Box, Button } from "@material-ui/core";
import { makeStyles, withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    button: {
        textTransform: 'none',
        backgroundColor: 'rgb(6, 62, 249)',
        boxShadow: 'none',
        fontFamily: 'RobotoLight',
        borderRadius: '8px',
        color: '#FFF',
        display: 'block',
        margin: 'auto',
        marginTop: '15px',
        paddingLeft: '10px',
        paddingRight: '10px'
    },
    href: {
        textDecoration: 'none'
    }
}));

const Error = () => {

    const classes = useStyles();

    return (
        <>
            <Box style={{ height: '100vh', justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                <Box>
                    <Typography variant="h2" align="center">404</Typography>
                    <Typography variant="h6" align="center">Página não encontrada.</Typography>
                    <a href="/" className={classes.href}>
                        <Button className={classes.button}>Ir para página inicial</Button>
                    </a>
                </Box>
            </Box>
        </>
    )
}

export default Error;